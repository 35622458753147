import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function Navigation() {
  // Selectors
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const cartVoucherValid = useSelector((state) => state.cartVoucherValid);
  const { voucherValid } = cartVoucherValid;

  const cartTax = useSelector((state) => state.cartTax);
  const { tax } = cartTax;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // State Etc
  // Off Canvas
  const [showNav, setShowNav] = useState(false);
  const handleCloseNav = () => setShowNav(false);
  const toggleShowNav = () => setShowNav((s) => !s);
  const backdropShowNav = false;
  const bodyScrollNav = true;

  // Cart Totals
  const cartLength = cartItems.length;
  let cartSub = cartItems.reduce((acc, item) => acc + item.price, 0).toFixed(2);
  let cartVoucherRate = parseFloat(voucherValid.discount);
  let cartVoucherTotal = (
    parseFloat(cartSub) * parseFloat(cartVoucherRate)
  ).toFixed(2);
  cartSub = (parseFloat(cartSub) - parseFloat(cartVoucherTotal)).toFixed(2);
  let cartTaxTotal;
  cartTaxTotal = (parseFloat(cartSub) * parseFloat(tax.vatRate)).toFixed(2);
  const cartTotal = (parseFloat(cartSub) + parseFloat(cartTaxTotal)).toFixed(2);

  return (
    <>
      <nav id="nav">
        <div className="d-print-none">
          <div className="navigation-large d-print-none d-none d-md-flex justify-content-between fixed-top">
            <div className="name d-flex align-items-center">
              <Link to="/">
                <img
                  src="/images/logo/gf-curl-inverse.svg"
                  width="140"
                  alt="GarageFonts logo"
                />
              </Link>
            </div>
            <div className="d-flex align-items-center">
              <nav className="navbar navbar-light">
                <Link to="/fonts">Fonts</Link>
                {/* <a
                  href="https://mailchi.mp/b40cd8d2bba0/garagefonts-news"
                  target="_blank"
                  rel="noreferrer"
                  className="info-nav"
                >
                  News
                </a> */}
                {cartLength > 0 ? (
                  <Link to="/cart" className="info-nav">
                    Cart: {cartLength}&nbsp;&#8211;&nbsp;$ {cartTotal}
                  </Link>
                ) : (
                  <Link to="/cart" className="info-nav">
                    Cart
                  </Link>
                )}
                {userInfo ? (
                  <Link to="/account/profile" className="info-nav">
                    Account
                  </Link>
                ) : (
                  <Link to="/login" className="info-nav">
                    Log in
                  </Link>
                )}
                <button
                  className="navbar-toggler menu-icon"
                  type="button"
                  onClick={toggleShowNav}
                >
                  <i className="bi bi-list"></i>
                </button>
              </nav>
            </div>
          </div>
          <div className="navigation-small d-print-none d-flex justify-content-between d-md-none fixed-top">
            <div className="name d-flex align-items-center">
              <Link to="/">
                <img
                  src="/images/logo/gf-curl-circle-black.svg"
                  width="60"
                  alt="GarageFonts logo"
                />
              </Link>
            </div>
            <div className="d-flex align-items-center">
              <nav className="navbar navbar-light">
                {cartLength > 0 ? (
                  <Link to="/cart" className="info-nav">
                    Cart: {cartLength}&nbsp;&#8211;&nbsp;$ {cartTotal}
                  </Link>
                ) : (
                  <Link to="/cart" className="info-nav">
                    Cart
                  </Link>
                )}
                <button
                  className="navbar-toggler menu-icon"
                  type="button"
                  onClick={toggleShowNav}
                >
                  <i className="bi bi-list"></i>
                </button>
              </nav>
            </div>
          </div>

          <div>
            <Offcanvas
              show={showNav}
              onHide={handleCloseNav}
              backdrop={backdropShowNav}
              scroll={bodyScrollNav}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>GarageFonts Menu</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <ul className="nav-overflow-list">
                  <li onClick={handleCloseNav}>
                    <Link to="/fonts">Fonts</Link>
                  </li>
                  <li onClick={handleCloseNav}>
                    <Link to="/gallery" className="fonts-nav">
                      Gallery
                    </Link>
                  </li>
                  <li onClick={handleCloseNav}>
                    <Link to="/specimens" className="fonts-nav">
                      Specimens
                    </Link>
                  </li>
                  <li onClick={handleCloseNav}>
                    <Link to="/trial-fonts" className="fonts-nav">
                      Trial Fonts
                    </Link>
                  </li>
                  <li onClick={handleCloseNav}>
                    <a
                      href="https://mailchi.mp/b40cd8d2bba0/garagefonts-news"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Newsletter
                    </a>
                  </li>
                  {/* <li onClick={handleCloseNav}>
                    <Link to="/blog" className="fonts-nav">
                      Blog
                    </Link>
                  </li> */}
                  <li onClick={handleCloseNav}>
                    {cartLength > 0 ? (
                      <Link to="/cart" className="info-nav">
                        Cart: {cartLength}&nbsp;&#8211;&nbsp;$ {cartTotal}
                      </Link>
                    ) : (
                      <Link to="/cart" className="info-nav">
                        Cart
                      </Link>
                    )}{" "}
                  </li>
                  <li onClick={handleCloseNav}>
                    {userInfo ? (
                      <Link to="/account/profile">Account</Link>
                    ) : (
                      <Link to="/login">Log in</Link>
                    )}
                  </li>
                  <li onClick={handleCloseNav}>
                    <Link to="/about" className="fonts-nav">
                      About
                    </Link>
                  </li>
                  <li onClick={handleCloseNav}>
                    <Link to="/support" className="fonts-nav">
                      Support
                    </Link>
                  </li>
                  <li onClick={handleCloseNav}>
                    <Link to="/contact" className="fonts-nav">
                      Contact
                    </Link>
                  </li>
                </ul>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>
        <div className="navigation-print d-none d-print-flex justify-content-center">
          <div className="name">
            <Link to="/">
              <img
                src="/images/logo/gf-curl-inverse.svg"
                width="140"
                alt="GarageFonts logo"
              />
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navigation;
