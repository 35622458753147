import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function Policies() {
  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <>
      <Container className="section-contain">
        <Helmet>
          <body className="section-bg" />
          <title>Policies - GarageFonts</title>
          <meta property="og:title" content="Policies - GarageFonts" />
        </Helmet>
        <Row>
          <Col md={12}>
            <h1 className="header">Support</h1>
          </Col>
        </Row>
        <Row>
          <Col lg={7}>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <Link to="/licenses" className="nav-link">
                  Licenses
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/eula" className="nav-link">
                  EULA
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/tiers" className="nav-link">
                  Tiers
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/discounts" className="nav-link">
                  Discounts
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/services" className="nav-link">
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/policies" className="nav-link active">
                  Policies
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col lg={7} className="mt-4">
            <p>PRIVACY POLICY</p>
            <p>
              Treating your privacy with respect is important to GarageFonts.
            </p>
            <p>We don't:</p>
            <p>
              (1) Collect your personal information, unless you choose to create
              an account with us or enter your details for a trial font
              download.
              <br />
              (2) Collect any other information while you browse our website.
              <br />
              (3) Store your credit card or PayPal information on our servers.
              <br />
              (4) Sell or give information about you to third parties.
            </p>
            <p>
              Your customer information is stored for the sole purpose of
              managing your account and purchases. Additionally it is used to
              keep you posted about free updates for typeface you licensed. You
              can update the personal information you provide on your personal
              page.
            </p>
            <p>
              Please get in touch if you like to receive a more detailed privacy
              policy, or when you choose to delete your account.
            </p>
            <p>-------------</p>
            <p>COOKIES</p>
            <p>
              A cookie is a small piece of data sent from a website and stored
              on the user's computer by the user's web browser while the user is
              browsing. Our website uses cookies to distinguish you from other
              users of our website. This helps us to provide you with a smooth
              experience when you browse our website. For instance, cookies
              enable our website to remember unique information about your visit
              such as your shopping cart content. Any cookies we may use are not
              linked to any personal information. Most web browsers
              automatically accept cookies, but you can set your browser
              preferences to block cookies and delete existing cookies from your
              browser. We recommend that you accept cookies so that all of the
              features of the website function properly.
            </p>
            <p>-------------</p>
            <p>RETURN POLICY</p>
            <p>
              We strongly recommend that you check your order carefully before
              proceeding to payment, because due to the digital nature of fonts,
              no refunds or exchanges will be given after the purchase is
              completed. There is no way for us to verify that you no longer
              hold copies of the fonts on your computer.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Policies;
